import React from 'react';
import EHealthAuth from "../pages/EhealthAuth";
import EHealthCallback from "../pages/EHealthAuthCallbackPage";
import NewMISLogin from "../pages/NewMISLogin";

const routes = [
    {
        path: '/mis-ehealth-auth/:token',
        component: () => <EHealthAuth/>,
        underAuth : false,
        hasOwnPage : true,
        showInNavigation: false,
    },{
        path: '/callback',
        component: () => <EHealthCallback/>,
        underAuth : false,
        hasOwnPage : true,
        showInNavigation: false,
    },
    {
        path: '/',
        component: () => <NewMISLogin/>,
        exact: true,
        underAuth : false,
        hasOwnPage : true,
        showInNavigation: false,
    },
];

export default routes;
