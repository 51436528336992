import {useHistory} from "react-router";
import React, {useEffect} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const EHealthAuth = () => {
    const history = useHistory()
    const token = history.location.pathname.split('/').pop();

    const addTokenToStorage = () => {
        localStorage.setItem("tmpToken", token);
    }


    useEffect(() => {
        if (token) {
            addTokenToStorage()
            window.close()
        }
    }, [token])

    useEffect(() => {
    }, [])


    return <div>
        <CircularProgress/>
    </div>
}

export default EHealthAuth;