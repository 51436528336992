import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.header.main,

    },
    innerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100vh',
        margin: '0 auto',
        maxWidth: '1056px',
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        maxWidth: '300px',
        maxHeight: '300px',
        margin: '0 auto',
    },
    contentText: {
        color: theme.palette.primary.dark,
        textAlign: 'center',
    },
    linkButton: {
        maxWidth: '300px',
        background: theme.palette.header.main,
        margin: '25px auto'
    },
}));

const PageNotFound = () => {
    const classes = useStyles();
    const history = useHistory();

    const handleTransition = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };

    return (
        <div className={classes.root}>
            <div className={classes.innerWrapper}>
                <div className={classes.contentWrapper}>
                    <div className={classes.contentText}>
                        <h1>404</h1>
                        <p>Сторінку не знайдено...</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
