import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(<App/>, document.getElementById('root'));

const element = document.getElementById('ipl-progress-indicator');
element.parentNode.removeChild(element);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
