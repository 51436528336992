import {ThemeProvider} from "@material-ui/core";
import themeMaterialUI from "./settings/themeMaterialUI";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import uk_utils from "date-fns/locale/uk";
import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import routes from "./settings/routes";
import withClearCache from "./ClearCache";
import PageNotFound from "./pages/PageNotFound";
import {useHistory} from "react-router";

const ClearCacheComponent = withClearCache(AppWrapper);

function ClearCacheApp() {
    return <ClearCacheComponent />;
}

function App () {
    const [open, setOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (!localStorage.getItem('infoMessage') && history.location.pathname !== '/covidPCR') {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [open]);

    return <ThemeProvider theme={themeMaterialUI}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uk_utils}>
            <Switch>
                {routes.map((route, index) => {
                    return  <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            render={props => (<route.component {...props} />)}
                        />})
                }
                <Route>
                    <PageNotFound />
                </Route>
            </Switch>
        </MuiPickersUtilsProvider>
    </ThemeProvider>
}


function AppWrapper () {
    return (<ThemeProvider theme={themeMaterialUI}>
                <BrowserRouter>
                        <App/>
                </BrowserRouter>
        </ThemeProvider>
    )
}

export default ClearCacheApp;
